import { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { useShowMaintenancePage } from "@/hooks";

import { ErrorPage } from "@/pages/ErrorPage";

const Layout = lazy(() => import("@/components/Layout"));
const App = lazy(() => import("@/App"));
const LandingPage = lazy(() => import("@/pages/LandingPage"));
const PublicViewPage = lazy(() => import("@/pages/PublicViewPage"));
const ResetPasswordPage = lazy(() => import("@/pages/ResetPasswordPage"));
const EulaPage = lazy(() => import("@/pages/EulaPage"));
const PrivacyPolicyPage = lazy(() => import("@/pages/PrivacyPolicyPage"));
const AnalysisExclusionsPage = lazy(
  () => import("@/pages/AnalysisExclusionsPage"),
);
const MaintenancePage = lazy(() => import("@/pages/MaintenancePage"));

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense>
        <Layout>
          <App />
        </Layout>
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/end-user-agreement",
    element: (
      <Suspense>
        <Layout>
          <EulaPage />
        </Layout>
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/privacy-policy",
    element: (
      <Suspense>
        <Layout>
          <PrivacyPolicyPage />
        </Layout>
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/analysis-exclusions",
    element: (
      <Suspense>
        <Layout>
          <AnalysisExclusionsPage />
        </Layout>
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/reset-password",
    element: (
      <Suspense>
        <Layout>
          <ResetPasswordPage />
        </Layout>
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "yarra-ranges",
    element: (
      <Suspense>
        <LandingPage />
      </Suspense>
    ),
  },
  {
    path: "report",
    element: (
      <Suspense>
        <PublicViewPage />
      </Suspense>
    ),
  },
]);

export function Router() {
  const showMaintenancePage = useShowMaintenancePage();

  if (showMaintenancePage) {
    return (
      <Suspense>
        <MaintenancePage />
      </Suspense>
    );
  }

  return <RouterProvider router={router} />;
}

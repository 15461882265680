import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { PostHogProvider } from "posthog-js/react";

import "shared/shared.css";
import "./main.css";

import { Providers } from "@/providers";
import { Router } from "@/components/Router";
import reportWebVitals from "./reportWebVitals";

import "@/lib/sentry";

const postHogApiKey = import.meta.env.VITE_REACT_PUBLIC_POSTHOG_KEY;
const postHogOptions = {
  api_host: import.meta.env.VITE_REACT_PUBLIC_POSTHOG_HOST,
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <StrictMode>
    <PostHogProvider apiKey={postHogApiKey} options={postHogOptions}>
      <Providers>
        <Router />
      </Providers>
    </PostHogProvider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
